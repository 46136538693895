import React, { createContext, useEffect, useState } from 'react'

export const AuthContext = createContext()

const AuthProvider = (props) => {
  const [user, setUser] = useState()
  const [isLogged, setIsLogged] = useState(true)

  const login = (userData) => {
    localStorage.setItem('adm_enky', JSON.stringify(userData));
    setUser(userData);
    setIsLogged(true);
};

useEffect(() => {
  // Check localStorage for existing auth data on component mount
  const userData = localStorage.getItem('adm_enky');
  console.log('Fetched user data:', userData)
  if (userData) {
      setUser(JSON.parse(userData));
      setIsLogged(true);
  } else {
      setIsLogged(false);
  }
}, [localStorage.getItem('adm_enky')]);

const logout = () => {
    localStorage.removeItem('adm_enky');
    setUser(null);
    setIsLogged(false);
};

  const value = {
    user,
    setUser,
    isLogged,
    setIsLogged,
    login,
    logout
  }
  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider