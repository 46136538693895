import React, { useContext, useEffect } from 'react'
import Topbar from '../components/topbar'
import Panel from '../components/panel'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Dashboard = (props) => {
  let adminEdit = false;
  const {
    general,
    setGeneral,
    orders,
    setOrders,
    transactions,
    setTransactions,
    admins,
    setAdmins,
    services,
    setServices,
    forms,
    setForms,
    featured,
    setFeatured,
    payments,
    setPayments,
    sms,
    setSms,
    latestOrders,
    setLatestOrders,
    latestTransactions,
    setLatestTransactions,
    adminDetails,
    setAdminDetails
  } = useContext(DetailsContext)
  const {
    user,
    setUser,
    isLogged,
    setIsLogged,
    login,
    logout
  } = useContext(AuthContext)
  props.setNavShow(true)
  function formatNumber(number) {
    return number.toLocaleString('en-US', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    });
}
  const columnsUsers = ['OrderID', 'Full Name', 'Email', 'Phone', 'ServiceID', 'Application Time', 'FeedBack','Status', 'Cost', 'Payment Type', 'TrackingID']
  const columnsTransaction = ['ID', 'Type', 'Transaction Reference', 'OrderID', 'Date', 'Amount', 'Status']
const navigate = useNavigate()
useEffect(() => {
  if (!isLogged) {
    navigate('/')
  }
}, [isLogged])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <div className="w3-container">
          <div className="w3-row w3-left w3-padding-24">
            <p className="w3-large text-black bold margin-0">Welcome back, {adminDetails && adminDetails.username}</p>
          </div>
          <div className="w3-right flex-row w3-padding-24">
            <p className="w3-text-purple">
              Dashboard
            </p>
            <p className="w3-small padding-sm">
              <i class="fa-solid fa-angles-right"></i>
            </p>
            <p className="text-dark">Admin</p>

          </div>
        </div>
        <div className="w3-container">
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Sales' icon='fa-solid fa-wallet' perc='4.6' value={`Kes ${general && formatNumber(general.total_sales)}`} color='blue'/>
            </div>
            <div className="w3-container w3-half">
              <Panel name='Total Expenses' icon='fa-solid fa-coins' perc='4.6' value={`Kes ${general && formatNumber(general.total_expenses)}`} color='pink'/>
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Profits' icon='fa-solid fa-receipt' perc='4.6' value={`Kes ${general && formatNumber(general.profits)}`} color='green' />
            </div>
            <div className="w3-container w3-half">
              <Panel name='Available Dividends' icon='fa-solid fa-percent' perc='4.6' value={`Kes ${general && formatNumber(general.share)}`} color='blue'/>
            </div>
          </div>
          <div className="w3-row">
            <div className="w3-container w3-half">
              <Panel name='Total Applications' icon='fa-solid fa-user' perc='4.6' value={general && general.total_application} color='purple' />
            </div>
            <div className="w3-container w3-half">
              <Panel name='Success Applications' icon='fa-solid fa-user-secret' perc='4.6' value={general && general.total_application}  color='yellow'/>
            </div>
          </div>
        </div>
        <div className="w3-container w3-padding">
          <Table name='Latest Applications' columns={columnsUsers} data={latestOrders ? latestOrders : []} edit={false} summary={true} info={true}/>
        </div>
        <div className="w3-container w3-padding">
          <Table name='Recent Transactions' columns={columnsTransaction} data={latestTransactions ? latestTransactions : []} edit={false} summary={true} />
        </div>
    </div>
  )
}

export default Dashboard