import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Transaction = () => {
    const {
        general,
        setGeneral,
        orders,
        setOrders,
        transactions,
        setTransactions,
        admins,
        setAdmins,
        services,
        setServices,
        forms,
        setForms,
        featured,
        setFeatured,
        payments,
        setPayments,
        sms,
        setSms,
        latestOrders,
        setLatestOrders,
        latestTransactions,
        setLatestTransactions,
        adminDetails,
        setAdminDetails
      } = useContext(DetailsContext)
      const {
        user,
        setUser,
        isLogged,
        setIsLogged,
        login,
        logout
      } = useContext(AuthContext)
    const transColumns = ['ID', 'Type', 'Transaction Reference', 'OrderID', 'Date', 'Status', 'Amount']
    const inputs = [
        {
          name: "transaction_type",
          icon: 'fa-solid fa-cube',
          type: 'select',
          vals: [{
            name: 'After', option: 'after'
          }],
        },
        {
          name: "transaction_reference",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter Transaction Reference',
        },
        {
          name: "orderID",
          icon: 'fa-solid fa-user',
          type: 'number',
          placeholder: 'Enter OrderID'
        },
        {
          name: "status",
          icon: 'fa-solid fa-user',
          type: 'select',
          vals: [
            {
            name: 'Pending', option: 'Pending'
            },
          {
            name: 'Success', option: 'Success'
          },
        ],
        },
        {
          name: "amount",
          icon: 'fa-solid fa-user',
          type: 'number',
          placeholder: 'Enter Amount',
        },
      ]
    function addTrans(){
        window.location.replace('/edit')
    }
        const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/transaction.php'
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={true} value='Add New Transactions' name='Transactions'/>
        <div className="w3-container">
        <Table tableName='transactions' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='Transactions Recieved' columns={transColumns} data={transactions} summary={false} edit={true} info={true}/>
        </div>
    </div>
  )
}

export default Transaction