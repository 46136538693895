import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthProvider'

export const DetailsContext = createContext()

const DetailsProvider = (props) => {
const {user} = useContext(AuthContext)
  const [general, setGeneral] = useState()
  const [orders, setOrders] = useState()
  const [transactions, setTransactions] = useState()
  const [admins, setAdmins] = useState()
  const [services, setServices] = useState()
  const [forms, setForms] = useState()
  const [featured, setFeatured] = useState()
  const [payments, setPayments] = useState()
  const [sms, setSms] = useState()
  const [latestOrders, setLatestOrders] = useState()
  const [latestTransactions, setLatestTransactions] = useState()
  const[adminDetails, setAdminDetails] = useState()
  const[information, setInformation] = useState()

  const fetchData = async(data) => {
    try {
        const response = await fetch('https://enky.xgramm.com/enky_backend/admins/getDetails.php', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        const result = await response.json()
        setGeneral(result.generals)
        setOrders(result.orders)
        setTransactions(result.transactions)
        setAdmins(result.admins)
        setServices(result.services)
        setForms(result.forms)
        setFeatured(result.featured)
        setPayments(result.payments)
        setSms(result.sms)
        setLatestOrders(result.latest_orders)
        setLatestTransactions(result.latest_transactions)
        setAdminDetails(result.admin_details)
        setInformation(result.information)

    } catch (error) {
        console.error("Error:", error)
    }
}

  useEffect( () => {
    fetchData(user)
    }, [user])

  const value = {
    general,
    setGeneral,
    orders,
    setOrders,
    transactions,
    setTransactions,
    admins,
    setAdmins,
    services,
    setServices,
    forms,
    setForms,
    featured,
    setFeatured,
    payments,
    setPayments,
    sms,
    setSms,
    latestOrders,
    setLatestOrders,
    latestTransactions,
    setLatestTransactions,
    adminDetails,
    setAdminDetails,
    information,
    setInformation,
    fetchData
  }
  return (
    <DetailsContext.Provider value={value}>
      {props.children}
    </DetailsContext.Provider>
  )
}

export default DetailsProvider