import React, { useContext, useEffect, useState } from 'react';
import Inputs from './inputs';
import SelectInputs from './selectInputs';
import ButtonLoad from './buttonLoad';
import { DetailsContext } from '../contexts/DetailsContext';

const Information = (props) => {
  const {information, setInformation} = useContext(DetailsContext)
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState()
  const close = () => {
    props.handleClose()
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    props.setData((prev) => ({ ...prev, [name]: value }));

  };
  function convertToObject(schemaStr) {
    // First, clean up the schema string
    const correctedStr = schemaStr
      .replace(/'/g, '"') // Replace single quotes with double quotes
      .replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":') // Add double quotes around keys
      .replace(/\r?\n|\r/g, ''); // Remove any newline characters
  
    // Parse the corrected string into a JavaScript object
    try {
      const obj = JSON.parse(correctedStr);
      return obj;
    } catch (error) {
      console.error("Error parsing schema:", error);
      return null;
    }
  }
  useEffect(() => {
    const specificTrackingID = props.data.trackingID;
    const result = information && information.filter(item => item.trackingID === specificTrackingID);
    if (result && result.length > 0) {
      // Assuming the first matched item is the one you need
      setFormData(convertToObject(result[0].information_schema));
    } else {
      // Handle case when no matching trackingID is found
      console.log('No matching item found');
      console.log(information)
    }
  }, [props.show])
  return (
    <div className={`modal fade ${props.show ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: props.show ? 'rgba(0,0,0,0.5)' : 'transparent' }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{props.value}</h5>
          <button type="button" className="btn-close" aria-label="Close" onClick={props.handleClose}></button>
        </div>
        <div className="modal-body">
          {/* iterate the object here to the input as child here */}
          {formData && formData ? (
              Object.keys(formData).map((key, index) => (
                <Inputs
                  key={index}
                  label={key} // Use title if available or default to key
                  icon={''} // Optional: Add icon if needed
                  type={'text'} // Default to 'text' if type is not specified
                  placeholder={''} // Placeholder from schema or default to empty
                  value={formData[key] || ''} // Populate with existing value from props.data
                  handleInpt={handleChange}
                  name={key} // Use key as the name to match data structure
                />
              ))
            ) : (
              <p>Loading...</p> // Show a loading indicator while formData is being set
            )}
        </div>
        <div className="modal-footer">
            <button type="button" className={`btn btn-danger`} onClick={props.handleClose}>
              Close
            </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Information