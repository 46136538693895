import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EditableTable from './editableTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Information from './information';
import { AuthContext } from '../contexts/AuthProvider';
import { DetailsContext } from '../contexts/DetailsContext';
import Complete from './complete';

  const Table = (props) => {
    const [modalData, setModalData] = useState({});
    const [viewModalData, setViewModalData] = useState({});
    const [compModalData, setCompModalData] = useState({});
    const [showComp, setComp] = useState(false)
    const [showView, setView] = useState(false)
    const [res, setRes] = useState({
      message: '',
      type: ''
    })
    const {user} = useContext(AuthContext)
    const {fetchData} = useContext(DetailsContext) 
    const data = props.data || [];
    const columns = props.columns || [];
    const dataLen = data.length
    let i = 1;
    // useEffect(() => {
    //   const countries = props.countries && props.countries.map(({ country, code }) => ({
    //     option: country,
    //     value: code
    //   }));
    //   if (countries) {
    //     setSelects(countries)
    //   }
    //   setSelects(countries)
    // }, [props.countries])

    const notifySuccess = () => {
      toast.success("Row Deleted Successful!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    
    const notifyError = () => {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 7;
  
    // Calculate the total number of pages
    const totalPages = Math.ceil(data.length / rowsPerPage);
  
    // Get the data for the current page
    const currentData = data.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const handleEditClick = (row) => {
      setModalData(row);
      props.setShowModal(true);
    };
    const handleView = (row) => {
      setViewModalData(row);
      setView(true);
    };
    const handleComplete = (row) => {
      setCompModalData(row);
      setComp(true);
    };
    const handleClose = () => {
      props.setShowModal(false);
      fetchData(user)
      setModalData()
      window.location.reload()
    };
    const handleCompleteClose = () => {
      setComp(false)
      fetchData(user)
      setCompModalData()
    }
    const handleViewClose = () => {
      setView(false)
      setViewModalData()
    }
  const tableName = props.tableName
  const handleDelete = (row) => {
    setModalData({table: tableName, ID: row['ID']});
    const postDelete = async() => {
      // Perform the update operation here
      try {
        const response = await fetch('https://enky.xgramm.com/enky_backend/admins/delete.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(modalData),
        })
        const result = await response.json()
        if (result.status === 'Success') {
          setRes({...res, message: result.message, type: true})
          notifySuccess()
          fetchData(user)
        }else{
          setRes({...res, message: result.message, type: false})
          notifyError()
        }
        
      } catch (error) {
        console.error("Error:", error)
      }
    }
    postDelete()
  };
    // Pagination logic with maximum 5 pages display
    const maxPagesToShow = 5;
    let startPage, endPage;
  
    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (maxPagesToShow - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
  
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return (
      <div style={{marginBottom: '20px'}} className="w3-row w3-round-large w3-white w3-card">
        <div style={{ height: '10vh', width: '100%' }} className="w3-row flex-row w3-padding w3-border-bottom border-grey">
          <div style={{ width: '5px', height: '5vh' }} className="t-elem w3-round-xlarge"></div>
          <p className="w3-medium margin-0 bold text-dark padding-sm">
            {props.name}
          </p>
        </div>
        <div className="w3-row w3-padding table-div table-responsive">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>#</th>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
                {props.edit && <th>Actions</th>}
                {props.info && <th>View</th>}
                {props.comp && <th>CompleteOrder</th>}
              </tr>
            </thead>
            <tbody>
              {currentData.map((row) => (
                <tr key={row.ID}>
                  <td>{i++}</td>
                  {Object.keys(row).map((key, index) => (
                    <td key={index}>
                      <span className={(key === 'status' && ['Success', 'Approved', 'Active', 'Completed'].includes(row[key])) ? 'badge bg-success' : (key === 'status' && ['Pending', 'Incomplete', 'Onprocess', 'Inactive'].includes(row[key])) ? 'badge bg-danger' : ''}>
                        {row[key]}
                      </span>
                    </td>
                  ))}
                  {props.edit && <td >
                    <p onClick={() => handleEditClick(row)} className="badge text-success w3-medium"><i className="fa-regular fa-pen-to-square"></i></p>
                    <span onClick={() => handleDelete(row)} className="badge text-danger w3-medium"><i className="fa-solid fa-trash-can"></i></span>
                  </td>}
                  {props.info && <td >
                    <p onClick={() => handleView(row)} className="badge text-success w3-medium"><i className="fa-regular fa-regular fa-eye"></i></p>
                  </td>}
                  {props.comp && <td >
                    <p onClick={() => handleComplete(row)} className="badge text-success w3-medium"><i className="fa-solid fa-circle-check"></i></p>
                  </td>}
                </tr>
              ))}
              {dataLen < 1 && (
                <tr>
                  <td colSpan={columns.length + 2} className="w3-large text-center">No data Available</td>
                </tr>
              )}
            </tbody>
          </table>
          
        </div>
        <div className={`w3-row ${props.summary ? 'w3-show' : 'w3-hide'} w3-padding`}>
          <div className="w3-right">
            <Link to='/users' className="text-success w3-small link">View All <i className="fa-solid fa-chevron-down"></i></Link>
          </div>
        </div>
        <div className={`w3-row ${props.summary ? 'w3-hide' : 'w3-show'} w3-padding`}>
        <div className="w3-right">
          <ul className="pagination pagination-sm">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
            </li>
            {startPage > 1 && (
              <>
                <li className="page-item">
                  <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                </li>
                {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
              </>
            )}
            {pages.map((page) => (
              <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
              </li>
            ))}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                <li className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                </li>
              </>
            )}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
            </li>
          </ul>
        </div>
        </div>
        <EditableTable 
          handleProdName={props.handleProdName} 
          show={props.showModal} 
          value="Edit Order" 
          handleClose={handleClose} 
          data={modalData || {}} 
          inputs={props.inputs} 
          setData={setModalData}
          link={props.link}
          type='edit'
        />
        <Information 
          handleProdName={props.handleProdName} 
          show={showView} 
          value="View Order Information" 
          handleClose={handleViewClose} 
          data={viewModalData || {}} 
          inputs={props.inputs} 
          setData={setViewModalData}
          link={props.link}
          type='edit'
        />
        <Complete 
          handleProdName={props.handleProdName} 
          show={showComp} 
          value="View Order Information" 
          handleClose={handleCompleteClose} 
          data={compModalData || {}} 
          setData={setCompModalData}
          type='file'
        />
        <ToastContainer/>
      </div>
    );
  };
  

export default Table;
