import React from 'react'
import Top from '../components/top'
import Table from '../components/table'
import working from '../media/images/working.png'

const Chat = () => {
  return (
<div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <div className="w3-container">
            <div className="w3-left flex-row w3-padding-24">
            <p className="w3-text-purple">
              Layout
            </p>
            <p className="w3-small padding-sm">
              <i class="fa-solid fa-angles-right"></i>
            </p>
            <p className="text-dark">Admin</p>
            </div>
        </div>
        <div className="w3-container grid-center">
            <div className="w3-panel">
                <img src={working} alt="" />
            </div>
            <p className="w3-xlarge"> We are working on this page</p>
        </div>
    </div>
  )
  
}

export default Chat