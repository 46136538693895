import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Admins = () => {
    const {
        general,
        setGeneral,
        orders,
        setOrders,
        transactions,
        setTransactions,
        admins,
        setAdmins,
        services,
        setServices,
        forms,
        setForms,
        featured,
        setFeatured,
        payments,
        setPayments,
        sms,
        setSms,
        latestOrders,
        setLatestOrders,
        latestTransactions,
        setLatestTransactions,
        adminDetails,
        setAdminDetails
      } = useContext(DetailsContext)
      const {
        user,
        setUser,
        isLogged,
        setIsLogged,
        login,
        logout
      } = useContext(AuthContext)
      const inputs = [
        {
          name: "name",
          icon: 'fa-solid fa-cube',
          type: 'text',
          placeholder: 'Enter name'
        },
        {
          name: "role",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter Admin Role',
        },
        {
          name: "job",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter Job'
        },
        {
            name: "description",
            icon: 'fa-solid fa-user',
            type: 'text',
            placeholder: 'Enter Admin Description',
          },
        {
          name: "passwrd",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Set Password'
        },
        {
          name: "permissions",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter permissions eg[edit]',
        },
        {
            name: "share",
            icon: 'fa-solid fa-user',
            type: 'text',
            placeholder: 'Enter Admin Share',
          },
          {
            name: "username",
            icon: 'fa-solid fa-user',
            type: 'text',
            placeholder: 'Enter Admin Username',
          },
          
      ]
    const adminColumns = [ 'AdminID','Name', 'Role', 'Description', 'Role Description', 'Password', 'Permissions', 'Share', 'Username']
    function addAdmin(){
        window.location.replace('/edit')
    }
            const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/admins.php'
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={true} value='Add New Admin' name='Admins'/>
        <div className="w3-container">
        <Table tableName='admins' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='All Admins' columns={adminColumns} data={admins} summary={false} edit={true}/>
        </div>
    </div>
  )
}

export default Admins