import React, { useContext, useEffect, useState } from 'react'
import Button from '../components/button'
import Top from '../components/top'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Service = () => {
    const {
        general,
        setGeneral,
        orders,
        setOrders,
        transactions,
        setTransactions,
        admins,
        setAdmins,
        services,
        setServices,
        forms,
        setForms,
        featured,
        setFeatured,
        payments,
        setPayments,
        sms,
        setSms,
        latestOrders,
        setLatestOrders,
        latestTransactions,
        setLatestTransactions,
        adminDetails,
        setAdminDetails
      } = useContext(DetailsContext)
      const {
        user,
        setUser,
        isLogged,
        setIsLogged,
        login,
        logout
      } = useContext(AuthContext)
      const inputs = [
        {
          name: "banner",
          icon: 'fa-solid fa-cube',
          type: 'text',
          placeholder: 'Enter Banner Image'
        },
        {
          name: "cost",
          icon: 'fa-solid fa-user',
          type: 'number',
          placeholder: 'Enter Cost',
        },
        {
          name: "name",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter Name'
        },
        {
            name: "description",
            icon: 'fa-solid fa-user',
            type: 'text',
            placeholder: 'Enter Admin Description',
          },
        {
          name: "status",
          icon: 'fa-solid fa-user',
          type: 'select',
          vals: [
            {
                name: 'Active',
                option: 'Active'
            },
            {
                name: 'Inactive',
                option: 'Inactive'
            }
          ]
        },
          
      ]
    let userColumns = ['ProdcutID', 'Image Banner', 'Cost', 'Name', 'Description', 'Status']
    let users = [
        {
            name: 'Academic Research',
            description: 'We offer High Quality Research!',
            cost: 'Kes 250.00',
            banner: 'img/hh',
            status: 'Active'
        }
    ]
    function addUsers(){
        window.location.replace('/edit')
    }
    const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/service.php'
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
  return (
    <div  style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
         <Top showModal={showModal} setShowModal={setShowModal} isAdd={true} value='Add New Service' name='Services'/>
        <div className="w3-container">
        <Table tableName='products' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='All Services' columns={userColumns} data={services} summary={false} edit={true}/>
        </div>
    </div>
  )
}

export default Service