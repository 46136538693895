import React, { useContext, useState } from 'react'
import Inputs from '../components/inputs'
import Button from '../components/button'
import ButtonLoad from '../components/buttonLoad'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../contexts/AuthProvider'

const Login = (props) => {
  const {
    user,
    setUser,
    isLogged,
    setIsLogged
} = useContext(AuthContext)
const [data, setData] = useState({
  username: '',
  password: ''
})
props.setNavShow(false)
const notifySuccess = (msg) => {
  toast.success(msg, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const notifyError = (msg) => {
  toast.error(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const navigate = useNavigate()

const [isLoading, setIsLoading] = useState(false) 
const [isSuccess, setIsSuccess] = useState(true);
const [message, setMessage] = useState('');
const [res, setRes] = useState('')
const handleUsername = (e) => {
  setData({...data, username: e.target.value})
}
const handlePassword = (e) => {
  setData({...data, password: e.target.value})
}
const handleLogin = async() => {
  setIsLoading(true)
  if (data.password && data.username) {
    try {
      const response = await fetch('https://enky.xgramm.com/enky_backend/admins/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const result = await response.json()
      
      if (result.status === 'Success') {
        setIsSuccess(true)
        localStorage.setItem('adm_enky', JSON.stringify({userID: result.Data.ID}))
        setUser({userID: result.Data.ID})
        notifySuccess(result.message)
        setTimeout(()=>{
          navigate('/dashboard')
        }, 2000)
        setIsLogged(true)
      }else{
        setIsSuccess(false)
        setIsLoading(false)
        notifyError(result.message)
      }
      setMessage(result.message)
      setRes(result.status)
  
  
    } catch (error) {
      console.error("Error:", error)
      setIsLoading(false)
      alert(error)
    }
  }else{
    setIsLoading(false)
    notifyError('Please Ensure All fields are filled!')
  }
  
}
  return (
    <div className='main grid-center'>
      <div style={{width: '100%'}} className="w3-container grid-center">
        <div style={{width: '100%', maxWidth: '400px', marginTop: '80px'}} className="w3-panel w3-round-large w3-card w3-white">
          <p className='w3-xxlarge text-center bold w3-padding'>Admin Login</p>
          <Inputs value={data.username} label='Username' type='text' placeholder='Enter your Username' icon='fa-regular fa-user' handleInpt={handleUsername} />
          <Inputs value={data.password} label='Password' type='password' placeholder='Enter your password' icon='fa-solid fa-lock' handleInpt={handlePassword} />
          {!isLoading && <Button value='Login' width='60' handleFunc={handleLogin} />}
          {isLoading && <ButtonLoad value='Processing...' width='60' />}
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Login