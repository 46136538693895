import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Forms = () => {
    const {
        general,
        setGeneral,
        orders,
        setOrders,
        transactions,
        setTransactions,
        admins,
        setAdmins,
        services,
        setServices,
        forms,
        setForms,
        featured,
        setFeatured,
        payments,
        setPayments,
        sms,
        setSms,
        latestOrders,
        setLatestOrders,
        latestTransactions,
        setLatestTransactions,
        adminDetails,
        setAdminDetails
      } = useContext(DetailsContext)
      const {
        user,
        setUser,
        isLogged,
        setIsLogged,
        login,
        logout
      } = useContext(AuthContext)
      const inputs = [
        {
          name: "form_schema",
          icon: 'fa-solid fa-cube',
          type: 'text',
          placeholder: 'Enter Form Schema In JSON FORMAT'
        },
        {
          name: "productID",
          icon: 'fa-solid fa-user',
          type: 'number',
          placeholder: 'Enter productID',
        },
          
      ]
    let catColumns = ['ID','Form Schema', 'ServiceID']
    let category = [
        {
            service: '51221',
            code: 'text{hfg: kjk}',
        }
    ]
    function addCat(){
        window.location.replace('/edit')
    }
        const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/forms.php'
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={true} value='Add New Form' name='Forms'/>
        <div className="w3-container">
        <Table tableName='form' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='All Services' columns={catColumns} data={forms} summary={false} edit={true}/>
        </div>
    </div>
  )
}

export default Forms