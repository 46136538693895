// Complete.js
import React, { useEffect, useState } from 'react';
import ButtonLoad from './buttonLoad';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Inputs = (props) => {
    const sendInput = (event) => {
        props.handleInpt(event);
    };

    return (
        <div className={`w3-padding ${(props.height) ? props.height : ''}`}>
            <p className={`w3-large ${(props.label) ? 'w3-show' : 'w3-hide'} text-dark margin-0`}>{props.label}</p>
            <div className='w3-row flex-row w3-text-grey w3-padding inputs w3-round-large'>
                <p className="w3-large margin-0">
                    <i className={props.icon}></i>
                </p>
                <input className='padding-sm' style={{ width: '100%', outline: 'none', border: 'none' }} 
                       onChange={sendInput} name={props.name} type={props.type} placeholder={props.placeholder} />
            </div>
        </div>
    );
};


const Complete = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [postData, setPostData] = useState({
        trackID: '',
        file: null,
        reference: ''
    });

    const notifySuccess = (msg) => {
        toast.success(msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      const notifyError = (msg) => {
        toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };

    useEffect(() => {
        if (props.data.trackingID) {
            setPostData((prev) => ({ ...prev, trackID: props.data.trackingID, reference: 'CompleteOrder' }));
        }
    }, [props.data.trackingID, props.show]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'file') {
            setPostData((prev) => ({ ...prev, file: files[0] })); // Store the selected file
        } else {
            setPostData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const update = async () => {
        const formData = new FormData();
        formData.append('file', postData.file);
        formData.append('reference', postData.reference);
        formData.append('trackID', postData.trackID);

        setIsLoading(true);
        try {
            const response = await fetch('https://enky.xgramm.com/enky_backend/admins/completeOrder.php', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            setIsLoading(false);
            // Handle response from the backend
           if (result.status == 'success') {
             notifySuccess(result.message)
           }else{
            notifyError(result.message)
           }
            // Optionally, you can update your state or show a success message
        } catch (error) {
            console.error('Error uploading the file:', error);
            setIsLoading(false);
        }
    };

    return (
        <div className={`modal fade ${props.show ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: props.show ? 'rgba(0,0,0,0.5)' : 'transparent' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Complete Order</h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={props.handleClose}></button>
                    </div>
                    <div className="modal-body">
                        <p className="w3-large">Order Reference: {props.data.trackingID || 'not working'}</p>
                        <Inputs
                            label={'Upload Preview Image'}
                            icon={'fa-regular fa-image'}
                            type={'file'}
                            handleInpt={handleChange}
                            name={'file'}
                        />
                    </div>
                    <div className="modal-footer">
                        {!isLoading && (
                            <button type="button" className={`btn btn-success`} onClick={update}>
                                Submit
                            </button>
                        )}
                        {isLoading && <ButtonLoad value='processing...' />}
                        <button type="button" className={`btn btn-danger`} onClick={props.handleClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default Complete;
