import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Form, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Navbar from './components/navbar';
import Topbar from './components/topbar';
import EditableTable from './components/editableTable';
import Orders from './pages/orders';
import Admins from './pages/admin';
import Transaction from './pages/transaction';
import Gateway from './pages/gateway';
import { useEffect, useState } from 'react';
import Service from './pages/service';
import Chat from './pages/chat';
import Sms from './pages/sms';
import Featured from './pages/featured';
import Forms from './pages/forms';

function App() {
  const [small, setSmall] = useState(true)
  const checkDocumentWidth = () => {
      if (document.documentElement.clientWidth > 600) {
        setSmall(false);
      } else {
        setSmall(true);
      }
    };
  
    useEffect(() => {
      // Initial check when the component mounts
      checkDocumentWidth();
  
      // Listen for window resize events
      window.addEventListener('resize', checkDocumentWidth);
  
      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', checkDocumentWidth);
      };
    }, []);
    const [isNavShow, setNavShow] = useState(true)
  return (
    <div className="App">
      <BrowserRouter>
      {isNavShow && <Navbar />}
      {isNavShow && <Topbar />}
      
      <div className={(small) ? 'margin-tp' : 'h'}>
      <Routes>
        <Route path='/dashboard' element={<Dashboard setNavShow={setNavShow}/>}/>
        <Route path='/' element={<Login setNavShow={setNavShow}/>}/>
        <Route path='/edit' element={<EditableTable />}/>
        <Route path='/orders' element={<Orders setNavShow={setNavShow}/>}/>
        <Route path='/transaction' element={<Transaction setNavShow={setNavShow}/>}/>
        <Route path='/service' element={<Service setNavShow={setNavShow}/>}/>
        <Route path='/admin' element={<Admins setNavShow={setNavShow}/>}/>
        <Route path='/chat' element={<Chat setNavShow={setNavShow}/>}/>
        <Route path='/form' element={<Forms setNavShow={setNavShow}/>}/>
        <Route path='/payment' element={<Gateway setNavShow={setNavShow}/>}/>
        <Route path='/sms' element={<Sms setNavShow={setNavShow}/>}/>
        <Route path='/featured' element={<Featured setNavShow={setNavShow}/>}/>
      </Routes>
        </div>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
