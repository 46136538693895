import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

const Navbar = () => {
    const [small, setSmall] = useState(true)
    const checkDocumentWidth = () => {
        if (document.documentElement.clientWidth > 600) {
          setSmall(false);
        } else {
          setSmall(true);
        }
      };
      useEffect(() => {
        // Initial check when the component mounts
        checkDocumentWidth();
    
        // Listen for window resize events
        window.addEventListener('resize', checkDocumentWidth);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', checkDocumentWidth);
        };
      }, []);

  return (
    <div id={(small) ?  'nav' : 'na'} className={`w3-container ${(small) ? 'collapse' : 'w3-show'} w3-bar my-scroll-div w3-top w3-col l3 m3 s5`}>
      <div className="w3-panel">
        <p className='w3-medium logo'>ADMIN</p>
      </div>
      <div className='w3-bar flex-column'>
        <NavLink to='/dashboard' end className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
                <i class="fa-solid fa-gauge"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Dashboard</p>
        </NavLink>
        <div style={{width: '100%'}} className="w3-row w3-bar-item">
        <p className='w3-text-grey bold margin-0'>Generals</p>
        </div>
        <NavLink to='/orders' end className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-cart-shopping"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Orders</p>
        </NavLink>
        <NavLink to='/transaction' end className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-receipt"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Transactions</p>
        </NavLink>
        <NavLink to='/chat' end className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-regular fa-comment"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Chat</p>
        </NavLink>
        <NavLink to='/admin' end className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-user-secret"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Admins</p>
        </NavLink>
        <div style={{width: '100%'}} className="w3-row w3-bar-item">
          <p className='w3-text-grey bold margin-0'>Controls</p>
        </div>
        <NavLink to='/service' className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-brands fa-intercom"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Services</p>
        </NavLink>
        <NavLink to='/form' className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-coins"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Forms</p>
        </NavLink>
        <NavLink to='/featured' className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-user-group"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Featured</p>
        </NavLink>
        <div style={{width: '100%'}} className="w3-row w3-bar-item">
          <p className='w3-text-grey bold margin-0'>External Services</p>
        </div>
        <NavLink to='/payment' className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-pager"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>Payments</p>
        </NavLink>
        <NavLink to='/sms' className="w3-row navi flex-row w3-padding link w3-round-large">
            <p className='w3-large margin-0'>
            <i class="fa-solid fa-pager"></i>
            </p>
            <p className='w3-medium w3-padding margin-0'>SMS</p>
        </NavLink>
    </div>
    </div>
    
  )
}

export default Navbar