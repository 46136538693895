import React, { useContext, useEffect, useState } from 'react'
import Button from '../components/button'
import EditableTable from '../components/editableTable'
import Table from '../components/table'
import Top from '../components/top'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Orders = () => {
  const {
    general,
    setGeneral,
    orders,
    setOrders,
    transactions,
    setTransactions,
    admins,
    setAdmins,
    services,
    setServices,
    forms,
    setForms,
    featured,
    setFeatured,
    payments,
    setPayments,
    sms,
    setSms,
    latestOrders,
    setLatestOrders,
    latestTransactions,
    setLatestTransactions,
    adminDetails,
    setAdminDetails
  } = useContext(DetailsContext)
  const {
    user,
    setUser,
    isLogged,
    setIsLogged,
    login,
    logout
  } = useContext(AuthContext)
    const orderColumns = ['OrderID', 'Full Name', 'Email', 'Phone', 'ServiceID', 'Application Time', 'FeedBack','Status', 'Cost', 'Payment Type', 'TrackingID']

    function addProducts(){
        window.location.replace('/edit')
    }
    const inputs = [
      {
        name: "name",
        icon: 'fa-solid fa-cube',
        type: 'text',
        placeholder: 'Enter Name',
      },
      {
        name: "email",
        icon: 'fa-solid fa-user',
        type: 'email',
        placeholder: 'Enter Email',
      },
      {
        name: "phone",
        icon: 'fa-solid fa-user',
        type: 'tel',
        placeholder: 'Enter Phone Number'
      },
      {
        name: "feedback",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter cycle',
      },
      {
        name: "cost",
        icon: 'fa-solid fa-user',
        type: 'number',
        placeholder: 'Enter Order Cost',
      },
      {
        name: "payment_type",
        icon: 'fa-solid fa-user',
        type: 'text',
        placeholder: 'Enter Payment Type',
      },
      {
        name: "status",
        icon: 'fa-solid fa-user',
        type: 'select',
        vals: [
          {name: 'Incomplete', option:'Incomplete'},
          {name: 'Onprocess', option:'Onprocess'},
          {name: 'Pending', option:'Pending'},
          {name: 'Success', option:'Success'},
          {name: 'Completed', option:'Completed'},
          

        ],
      }
    ]
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
    const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/orders.php'
  return (
    <div  style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
            <Top showModal={showModal} setShowModal={setShowModal} isAdd={false} value='Add New Product' name='Products'/>
        <div className="w3-container">
        <Table tableName='orders' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='Orders Made' columns={orderColumns} data={orders} summary={false} edit={true} info={true} comp={true}/>
        </div>
    </div>
  )
}

export default Orders