import React, { useContext, useEffect, useState } from 'react'
import Top from '../components/top'
import Table from '../components/table'
import { DetailsContext } from '../contexts/DetailsContext'
import { AuthContext } from '../contexts/AuthProvider'
import { useNavigate } from 'react-router-dom'

const Sms = () => {
    const {
        general,
        setGeneral,
        orders,
        setOrders,
        transactions,
        setTransactions,
        admins,
        setAdmins,
        services,
        setServices,
        forms,
        setForms,
        featured,
        setFeatured,
        payments,
        setPayments,
        sms,
        setSms,
        latestOrders,
        setLatestOrders,
        latestTransactions,
        setLatestTransactions,
        adminDetails,
        setAdminDetails
      } = useContext(DetailsContext)
      const {
        user,
        setUser,
        isLogged,
        setIsLogged,
        login,
        logout
      } = useContext(AuthContext)
      const inputs = [
        {
          name: "provider",
          icon: 'fa-solid fa-cube',
          type: 'text',
          placeholder: 'Enter Provider Name'
        },
        {
            name: "fee",
            icon: 'fa-solid fa-cube',
            type: 'text',
            placeholder: 'Enter Fee'
          },
        {
          name: "message",
          icon: 'fa-solid fa-user',
          type: 'text',
          placeholder: 'Enter Message'
        },
        {
            name: "reciever",
            icon: 'fa-solid fa-user',
            type: 'text',
            placeholder: 'Enter Reciever Phone in form (+2547...)'
          },
    ]
    const contColumns = ['ID', 'Provider Name', 'Fee', 'Message', 'Reciever']
    function addCont(){
        window.location.replace('/edit')
    }
    const [showModal, setShowModal] = useState(false);
    const link = 'https://enky.xgramm.com/enky_backend/admins/sms.php'
    const navigate = useNavigate()
    useEffect(() => {
      if (!isLogged) {
        navigate('/')
      }
    }, [isLogged])
  return (
    <div style={{position: 'relative'}} className='w3-col l9 w3-right m9 s12 main'>
        <Top showModal={showModal} setShowModal={setShowModal} isAdd={true} value='Add New SMS' name='SMS'/>
        <div className="w3-container">
        <Table tableName='sms' link={link} inputs={inputs} showModal={showModal} setShowModal={setShowModal} name='SMS Management' columns={contColumns} data={sms} summary={false} edit={true}/>
        </div>
    </div>
  )
}

export default Sms