import React from 'react'

const SelectInputs = (props) => {
    const values = props.values
  return (
    <div className="w3-padding">
      <p className={`w3-large ${(props.label) ? 'w3-show' : 'w3-hide'} text-dark margin-0`}>{props.label}</p>
      <div className='w3-row flex-row w3-text-grey w3-padding inputs w3-round-large'>
        <p className="w3-large  margin-0">
            <i className={props.icon}></i>
        </p>
        <select className='padding-sm' style={{width: '100%', outline: 'none', border: 'none'}} value={props.value} onChange={(e) => props.handleInpt(e)} name={props.name}>
          <option disabled-selected-value>Select here</option>
            {values.map((val) => (
                <option value={val.option}>{val.name}</option>
            )
            )}
        </select>
    </div>
    </div>
  )
}

export default SelectInputs